<template>
    <div class="option_rgpd">
        <h3>RGPD: Règlement général sur la protection des données</h3>
        <vs-divider/>

        <vs-row class="container">
            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                <vs-button color="primary" type="filled" disabled>Journaux enregistrements</vs-button>
                <vs-button color="primary" type="filled" disabled>Base d'exclusion</vs-button>
            </vs-col>
        </vs-row>
        <br/>

        <vx-card>
            <h2>Le délégué à la protection des données (DPO):</h2>
            <br/>
            <table class="w-full">
                <tr v-for="dpo in dpo_list" :key="dpo.uid">
                    <td>DPO:</td>
                    <td><b>{{ dpo.member }}</b></td>
                    <td>{{ dpo.createAt }}</td>
                </tr>
            </table>
            <vs-divider/>
            <vs-button color="success" type="filled" v-on:click="addDpo">Ajouter</vs-button>
            
            <br/><br/><br/>
            <h2>Spécificités techniques (droit à l'oubli):</h2>
            <br/>
            <table>
                <tr>
                    <td>Contacts:</td>
                    <td>36 mois</td>
                </tr>
                <tr>
                    <td>Essais:</td>
                    <td>36 mois</td>
                </tr>
                <tr>
                    <td>Membres:</td>
                    <td>36 mois</td>
                </tr>
            </table>
            <br/><br/><br/>
            <h2>Informations relatives à l'application de la RGPD :</h2>
            <br/>
            <a href="https://www.cnil.fr/fr/rgpd-par-ou-commencer" target="_blank">Introduction</a><br/>
            <a href="https://www.cnil.fr/fr/rgpd-de-quoi-parle-t-on" target="_blank">RGPD information</a><br/>
        </vx-card>
        <SelectMember ref="selectMember" />
    </div>
</template>



<style lang="scss">
.option_rgpd{
    
}
</style>

<script>

import autoRefresh from '@/assets/utils/autoRefresh'

import Dpo  from '@/database/models/dpo'
import Member from '@/database/models/member'

import Utils from '@/assets/utils/utils'
import Fdate from '@/assets/utils/fdate';

import SelectMember from '@/components/member/select.vue'

export default {
    components:{
        SelectMember,
	},
    data(){
        return{
            dpo_list:[],
        }
	},
	mounted:function()
	{
        //autoRefresh
		autoRefresh.SetCallback(()=>{
            //refresh
            Dpo.getTabAllMemory((list_dpo)=>{

                this.dpo_list = []
                for( var i=0; i<list_dpo.length; i++)
                Member.getMemory( list_dpo[i].member, (mbr)=>
                {
                    this.dpo_list.push({
                        member  : Utils.StrCapital( mbr.first_name+" "+mbr.last_name ),
                        createAt: Fdate.getFullDate(list_dpo[i].createAt)
                    })
                })
            })
        })
        autoRefresh.refresh()
    },
    watch:{
        $route (){
            
        }
    },
    methods:{
        addDpo()
        {
            this.$refs.selectMember.openPopup((mbr_select)=>{
                //add dpo
                Dpo.add({
                    member:mbr_select.uid
                })
                .then(()=>{
                    autoRefresh.refresh()
                })
			})
        }
    }
};

</script>